<template>
   <!-- <div class="ms_register_popup"> -->
   <!----Login Popup Start---->
        <!-- <div class="modal  centered-modal" role="dialog"> -->
            <div class="modal-dialog login_dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="ms_register_img">
                            <img src="../../assets/images/Sudani_logo.png" alt="" class="img-fluid" />
                        </div>
                        <div class="ms_register_form">
                            <h2>تسجيل الدخول</h2>
                            <form  @submit.prevent="onLogin()">
                            <div class="form-group">
                                <input type="text" v-model="msisdn" placeholder="رقم الهاتف" class="form-control">
                                <!-- <span class="form_icon">
							       <i class="fa_icon form-user" aria-hidden="true"></i>
						        </span> -->
                            </div>
                            <!-- <a href="profile.html" class="ms_btn" target="_blank">تسجيل الدخول</a> -->
                            <a @click="onLogin()" class="ms_btn pointer">
                                <span v-if="loading" class="px-1">جاري تسجيل الدخول</span> &nbsp;
                                <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                                <span v-else class="">تسجيل الدخول</span>
                            </a>
                            <p>ليس لديك اشتراك? <a @click="Subscrib()" data-toggle="modal" class="ms_modal1 hideCurrentModel pointer">اشتراك الان</a></p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        <!-- </div> -->
   <!-- </div> -->
</template>

<script>
import { HTTPSPAY } from '@/Api/http-spay';
import { useToast } from "vue-toastification";
export default {
   name: 'AppLogin',
   data() {
      return {
         msisdn: "",
         loading: false,
      };
   },
    setup() {
        const Subscrib = () => {
            window.open("http://sudanipay.com/subscription/nagham/Nagham", "_self");
        }
        return { Subscrib }
    },
    mounted() {
   if (this.$cookie.isCookieAvailable("msisdn") && this.$cookie.isCookieAvailable("status")) {
        this.$router.push({ path: "/" })
    }
  },
  methods: {
    async onLogin() {
      const toast = useToast();
      if(this.msisdn.startsWith(9)) {
          toast.error("رقم الهاتف ليس سوداني", { timeout: 2500, });
      } else {
      if (this.msisdn != "") {
        const str = this.msisdn;
        if (str.substr(0, 1) === "0") {
          let number = str.substr(1);
          if (number.startsWith(1)) {
            this.phone = number;
          } else {
            toast.error("رقم الهاتف ليس سوداني", { timeout: 2500,});
          }
        } else if (str.substr(0, 1) === "2" && str.length === 12) {
          let number = str.substr(3);
          if (number.startsWith(1)) {
            this.phone = number;
          } else {
            toast.error("رقم الهاتف ليس سوداني", { timeout: 2500,});
          }
        } else if (str.substr(0, 1) === "1" && str.length === 9) {
          let number = str.substr();
          if (number.startsWith(1)) {
            this.phone = number;
          } else {
            toast.error("رقم الهاتف ليس سوداني", { timeout: 2500, });
          }
        } else if (str.length != 9) {
          toast.error("رقم الهاتف يجب ان يتكون من 10 ارقام", { timeout: 2500,});
        }
          if (this.phone.startsWith(1)) {
            this.loading = true;
            await HTTPSPAY.post(`CheckLogin.php?username=249`+this.phone +`&serviceId=2222&operatorID=2`).then((res) => {
                if (res.data.status === 1 && res.data.MINTUES > 0) {
                    this.$cookie.setCookie("msisdn", "249" + this.phone, { expire: 60 * res.data.MINTUES, });
                    this.$cookie.setCookie("status", res.data.status, { expire: 60 * res.data.MINTUES,});
                    this.$cookie.setCookie("remming_minutes", res.data.MINTUES, { expire: 60 * res.data.MINTUES,});
                    toast.success("تم تسجيل الدخول استمتع بتجربة فريدة", { timeout: 2500 });
                    setTimeout(() => this.$router.push({ path: "/" }), 2500);
                } else if (res.data.status === 1 && res.data.MINTUES < 0) {
                    toast.success("تم تسجيل الدخول", { timeout: 2500, });
                    this.$cookie.setCookie("msisdn", "249" + this.phone, { expire: 60 * 120, });
                    this.$cookie.setCookie("status", res.data.status, { expire: 60 * 120,});
                    this.$cookie.setCookie("remming_minutes", res.data.MINTUES, { expire: 60 * 120, });
                } else if (res.data.status === 0 && res.data.responseCode === 118) {
                    this.$cookie.setCookie("msisdn", "249" + this.phone, { expire: 60 * 120, });
                    toast.warning("عفوا لم يتم تجديد اشتراكك", { timeout: 2500, });
                    setTimeout(() => { window.open("http://sudanipay.com/subscription/nagham/Nagham", "_self"); }, 2500);
                    // setTimeout(() => this.$router.push({ path: "/" }), 2500);
                } else if (res.data.status === 0) {
                    toast.warning("عفوا لست مشترك في منصة نغم", { timeout: 2500, });
                    setTimeout(() => { window.open("http://sudanipay.com/subscription/nagham/Nagham", "_self"); }, 2500);
                } else {
                    toast.info("عفوا لست مشترك في منصة نغم" , { timeout: 2500 });
                    setTimeout(() => { window.open("http://sudanipay.com/subscription/nagham/Nagham", "_self"); }, 2500);
                }
              }).catch((error) => {
                console.log(error);
              }).finally(() => {
                this.loading = false;
              });
          } else {
            toast.info("رقم الهاتف ليس سوداني", { timeout: 2500 });
          }
      } else {
        toast.info("الرجال ادخال رقم الهاتف", { timeout: 2500 });
      }
      }      
    },
  },
}
</script>

<style>

</style>